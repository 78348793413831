import Swiper, { Autoplay, Lazy, Navigation, Pagination, Thumbs } from "swiper";

import ObjectAssignDeep from "object-assign-deep";

Swiper.use([Autoplay, Lazy, Navigation, Pagination, Thumbs]);


class PaginationProgress {
  constructor(opts) {
    this.defaultOptions = {
      $container: null,
      updateInterval: 4,
      autoPlaySpeed: 5e3,
      onBarIsFilled: () => {
      }
    };

    this.options = Object.assign({}, this.defaultOptions, opts),
      this.progressBarTimer = null,
      this.progressBarCounterValue = 0;
  }

  start() {
    this.progressBarTimer && clearInterval(this.progressBarTimer);
    const that = this;
    this.progressBarTimer = setInterval(function() {
      that.updateProgressBar(that);
    }, that.options.updateInterval);
  }

  pause() {
    clearInterval(this.progressBarTimer);
  }

  reset(start = true) {
    this.progressBarCounterValue = 0;
    this.setProgressBar(this.progressBarCounterValue, this);
    if (start) {
      this.start();
    }
  }

  updateProgressBar(that) {
    that.progressBarCounterValue += 100 / (that.options.autoPlaySpeed / that.options.updateInterval);
    that.setProgressBar(that.progressBarCounterValue, that);
    that.progressBarCounterValue >= 100 && (that.options.onBarIsFilled(), that.reset());
  }

  setProgressBar(t, that) {
    that.options.$container && that.options.$container.style.setProperty("--w", `${t}%`);
  }
}


export default class HeroSlider {
  constructor(slider, prefixClass) {
    this.slider = slider;
    this.progress = 0;
    this.prefix = prefixClass;
    this.autoplay = true;
    this.autoplayDelay = 4500;

    this.paginationProgress = new PaginationProgress(
      {
        $container: slider,
        onBarIsFilled: () => this.swiper.slideNext()
      }
    );

    this.initSwiper();
  }

  initSwiper() {
    const that = this;
    const interleaveOffset = 1;
    const params = {
      wrapperClass: that.prefix + "__wrapper",
      slideClass: that.prefix + "__slide",
      slideActiveClass: that.prefix + "__slide--active",
      preloadImages: true,
      lazy: {
        loadPrevNext: true,
        loadPrevNextAmount: 1
      },
      allowTouchMove: false,

      loop: true,
      watchSlidesProgress: true,
speed: 800,
      on: {
        progress(swiper) {
          for (let i = 0; i < swiper.slides.length; i++) {
            const slideProgress = swiper.slides[i].progress;
            const innerOffset = swiper.width * interleaveOffset;
            const innerTranslate = slideProgress * innerOffset;
            swiper.slides[i].querySelector(".slide-inner").style.transform =
              `translate3d(${innerTranslate}px, 0, 0)`;
          }
        },
        // touchStart() {
        //   const swiper = this;
        //   for (let i = 0; i < swiper.slides.length; i++) {
        //     swiper.slides[i].style.transition = "";
        //   }
        // },
        setTransition(swiper, speed) {
          for (let i = 0; i < swiper.slides.length; i++) {
            // eslint-disable-next-line no-param-reassign
            swiper.slides[i].style.transition = `${speed}ms`;
            // eslint-disable-next-line no-param-reassign
            swiper.slides[i].querySelector(".slide-inner").style.transition =
              `${speed}ms`;
          }
        }
      }
    };

    //init swiper
    const thumbPrm = Object.assign({},params);
    const thumbParams = ObjectAssignDeep({}, thumbPrm, {
        wrapperClass: "hero-slider-thumb__wrapper",
        slideClass: "hero-slider-thumb__slide",
        slideActiveClass: "hero-slider-thumb__slide--active",
        slideNextClass: "hero-slider-thumb__slide--next",
        lazy: {
          loadPrevNextAmount: 2
        }
      }
    );
    const heroThumbSwiper = new Swiper(".hero-slider-thumb", thumbParams);


    const navigation = this.slider.querySelector("." + that.prefix + "__pagination");
    if (navigation) {
      params.pagination = {
        el: "." + that.prefix + "__pagination",
        type: "custom",
        renderCustom(swiper, current, total) {
          let next = current + 1;
          if (current + 1 > total) {
            next = 1;
          }
          const currentString = String(current);
          const nextString = String(next);
          return `<span class="${that.prefix}__pagination-number"><span>${currentString.padStart(2, 0)}</span></span> <span class="${that.prefix}__pagination-spacer"></span> <span class="${that.prefix}__pagination-number"><span>${nextString.padStart(2, 0)}</span></span>`;
        }
      };
    }


    this.swiper = new Swiper(this.slider, Object.assign({},{
      // thumbs: {
      //   swiper: heroThumbSwiper
      // },
    },ObjectAssignDeep({}, params, {

      navigation: {
        nextEl: ".hero-slider-thumb__next"
      },
      on: {
        init(swiper) {
          setTimeout(() => {
            that.paginationProgress.start();
            // that.drawProgress(swiper);
          }, 200);
        },
        slideChange(){
          setTimeout(()=>{
            heroThumbSwiper.slideNext();
          },150)
        },
        transitionStart(swiper) {
          that.paginationProgress.reset(false);

        },
        transitionEnd(swiper) {
          that.paginationProgress.start();
          // that.drawProgress(swiper);
        }
      }
    })));

    this.swiper.navigation.$nextEl[0].addEventListener("mouseenter", () => {
      this.pauseProgress();
    });
    this.swiper.navigation.$nextEl[0].addEventListener("focus", () => {
      this.pauseProgress();
    });
    this.swiper.navigation.$nextEl[0].addEventListener("mouseleave", () => {
      this.unpauseProgress();
    });
    this.swiper.navigation.$nextEl[0].addEventListener("blur", () => {
      this.unpauseProgress();
    });

  }

  pauseProgress() {
    this.paginationProgress.pause();
  }

  unpauseProgress() {
    this.paginationProgress.start();
  }


}
