import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class FormLabel{
  constructor(label,wrapper) {
    const block = document.querySelector(label);

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: wrapper,
        scrub: true
      },
    });
    tl.fromTo(block,{
        yPercent: -100,
        rotation:-20,
        transformOrigin:'left center',
        duration:1, ease:'none'
      },
      {
        yPercent: 100,
        rotation:10,
        transformOrigin:'left center',
        duration:1, ease:'none'
      })
  }
}
