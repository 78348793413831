import Swiper, { Lazy, Pagination, Thumbs } from "swiper";

Swiper.use([Lazy, Pagination, Thumbs]);
export default class ComfortSlider {
  constructor(slider, prefixClass) {
    this.slider = slider;
    this.progress = 0;
    this.prefix = prefixClass;
    this.initSwiper();
  }

  initSwiper() {
    const that = this;
    const interleaveOffset = 0.5;
    const params = {
      wrapperClass: that.prefix + "__wrapper",
      slideClass: that.prefix + "__slide",
      slideActiveClass: that.prefix + "__slide--active",
      // loop: true,
      // spaceBetween: 20,
      slidesPerView: "auto",
      slideToClickedSlide: true,
      preloadImages: true,
      watchSlidesProgress: true,
      lazy: {
        loadPrevNext: true,
        loadPrevNextAmount: 1,
        elementClass: `${that.prefix}__image`
      },
      speed: 800,

      on: {
        progress() {
          if(window.innerWidth > 1023){
          const swiper = this;
          for (let i = 0; i < swiper.slides.length; i++) {
            const slideProgress = swiper.slides[i].progress;
            const innerOffset = swiper.width * interleaveOffset;

            const innerTranslate = slideProgress * innerOffset;
            swiper.slides[i].querySelector(".comfort-slider__slide-inner").style.transform =
              `translate3d(${innerTranslate}px, 0, 0)`;
          }
          }
        },
        setTransition(swiper, speed) {
          if(window.innerWidth > 1023) {
            for (let i = 0; i < swiper.slides.length; i++) {
              // eslint-disable-next-line no-param-reassign
              swiper.slides[i].style.transitionDuration = `${speed}ms`;
              // eslint-disable-next-line no-param-reassign
              swiper.slides[i].querySelector(".comfort-slider__slide-inner").style.transitionDuration =
                `${speed}ms`;
            }
          }
        }
      }
    };
    const bullets = document.querySelector("." + that.prefix + "__pagination");
    if (bullets) {
      params.pagination = {
        el: "." + that.prefix + "__pagination",
        type: "bullets",
        clickable: true,
        bulletElement: "button",
        bulletClass: that.prefix + "__bullet",
        bulletActiveClass: that.prefix + "__bullet--active",
        renderBullet(i, className) {
          const text = this.slides[i].querySelector("." + that.prefix + "__title").textContent;
          return `<button class="${className}">
<svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
 <path d="M26.5 14v-1C19.6 13 14 7.4 14 .5h-1C13 7.4 7.4 13 .5 13v1C7.4 14 13 19.6 13 26.5h1C14 19.6 19.6 14 26.5 14z"/>
</svg>
<span>${text}</span></button>`;
        }
      };
    }
    const thumbParams = Object.assign({}, {
        wrapperClass: this.prefix+"-thumb__wrapper",
        slideClass: this.prefix+"-thumb__slide",
        slideActiveClass: this.prefix+"-thumb__slide--active",
      slidePrevClass: this.prefix+"-thumb__slide--prev",
      slideNextClass: this.prefix+"-thumb__slide--next",
      allowTouchMove: false,
      watchSlidesProgress: true,
      spaceBetween: 20,
      virtualTranslate: true,

      }
    );
    this.thumbSwiper = new Swiper(`.${this.prefix}-thumb`, thumbParams);


    //init swiper
    this.initSwiperInstance(params);

    const breakpointSlider = window.matchMedia("(min-width:1024px)");
    breakpointSlider.addEventListener('change',()=>{
      this.swiper.destroy(true);
      const inner = this.slider.querySelectorAll('.comfort-slider__slide-inner');
      [...inner].forEach(el=>{
        el.removeAttribute('style');
      })
      this.initSwiperInstance(params);
    });

    window.addEventListener('load',()=>{
      const that = this;
      setTimeout(()=>{
        for(var i in that.swiper.slides){
          that.swiper.lazy.loadInSlide(i);
        }
      },500);
    })
  }
  initSwiperInstance(params){
    const that = this;

    this.swiper = new Swiper(this.slider, Object.assign({},{
      watchSlidesProgress: true,
      lazy: {
        loadPrevNext: true,
        loadPrevNextAmount: 4,
        elementClass: `${that.prefix}__image`
      },
      thumbs: {
        swiper:that.thumbSwiper
      },
      slidesPerView: 1,
      breakpoints:{
        1024:{
          watchSlidesProgress: true,
          // allowTouchMove: false,
        }
      }
    },params));
  }
}
