import FlexMasonry from "flexmasonry/src/flexmasonry";
import LazyLoad from "vanilla-lazyload";

import Inputmask from "inputmask";
import ComfortSlider from "./lib/comfortSlider";
import HeroSlider from "./lib/heroSlider";
import FormLabel from "./lib/formLabel";
import Form from "./lib/form";


function prepareImages(selector) {
  if (typeof selector === "undefined") {
    // eslint-disable-next-line no-param-reassign
    selector = "img[width][height]";
  }
  const images = document.querySelectorAll(selector);
  const imagesLength = images.length;

  for (let i = 0, count = imagesLength; i < count; i++) {
    const image = images[i];
    const width = image.getAttribute("width");
    const height = image.getAttribute("height");
    const ratio = width / height;
    image.style.height = `${image.offsetWidth / ratio}px`;
    image.addEventListener("load", () => {
      image.style.height = "";
    });
    image.src = image.src;
  }
}

document.addEventListener("DOMContentLoaded", () => {
  prepareImages();
  if (document.querySelector(".hero-slider")) {
    const heroSlider = new HeroSlider(document.querySelector(".hero-slider"), "hero-slider");
  }

  if (document.querySelector(".comfort-slider")) {
    const comfortSlider = new ComfortSlider(document.querySelector(".comfort-slider"), "comfort-slider");
  }


  if (document.querySelector(".what-grid")) {
    const flexMasonry = FlexMasonry.init(".what-grid", {
      responsive: true,
      breakpointCols: {
        "min-width: 700px": 2
      }
    });
  }

  const lazyLoad = new LazyLoad({
    elements_selector: ".lazy-load",
    use_native: true
  });

  if (document.querySelector(".form") && document.querySelector(".form__label-wrapper")) {
    const label = new FormLabel(".form__label-wrapper", ".form");
  }

  const form = new Form();


// init masked input for type=tel
  Inputmask({
    mask: "+7 999 999-99-99",
    showMaskOnHover: false
  }).mask("input[type=tel]");

  const links = document.querySelectorAll('a[href^="#"]');

  [...links].forEach(link => {
    link.addEventListener("click", e => {
      const href = e.currentTarget.getAttribute("href");
      const target = document.querySelector(href);
      if (target) {
        e.preventDefault();
        window.history.pushState(null, document.title, `#${target.id}`);
        let {top} = target.getBoundingClientRect();
        if(target.id === 'become'){
          top -= 60
        }
        top += window.scrollY;

        window.scroll({
          top,
          behavior: "smooth"
        });
      }

    });
  });

})
;

window.addEventListener("load", () => {
  setTimeout(() => {
    document.body.classList.add("loaded");
  }, 500);

});
