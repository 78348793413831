import Bouncer from 'formbouncerjs';

export default class Form{
  constructor() {
    const formsValidate = document.querySelectorAll('form[data-validate]');
    if ([...formsValidate].length) {
      // base options
      const validateOptions = {
        disableSubmit: true,
        fieldClass: 'form__control--error', // Applied to fields with errors
        errorClass: 'form__error-message', // Applied to the error message for invalid fields
        fieldPrefix: 'bouncer-field_', // If a field doesn't have a name or ID, one is generated with this prefix
        errorPrefix: 'bouncer-error_', // Prefix used for error message IDs
      };
      const {lang} = document.documentElement;
      // change only ru, eng is default
      if (lang === 'ru') {
        validateOptions.messages = {
          missingValue: {
            checkbox: 'Поле обязательно.',
            radio: 'Выберите значение.',
            select: 'Выберите значение.',
            'select-multiple': 'Выберите минимум одно из значений.',
            default: 'Заполните это поле.'
          },
          patternMismatch: {
            email: 'Введите корректный email адрес.',
            url: 'Введите корректный URL.',
            number: 'Введите число',
            color: 'Должно соответствовать формату: #rrggbb',
            date: 'Должно соответствовать формату YYYY-MM-DD',
            time: 'Должно соответствовать формату 23:00',
            month: 'Должно соответствовать формату YYYY-MM',
            default: 'Несоответствие формату.'
          },
          outOfRange: {
            over: 'Значение должно быть меньше {max}.',
            under: 'Значение должно быть больше {min}.'
          },
          wrongLength: {
            over: 'Сократите текст до {maxLength} знаков. Сейчас {length} знаков.',
            under: 'Увеличьте текст до {minLength} знаков и более. Сейчас {length} знаков.'
          },
        };
      }
      const formBouncer = new Bouncer('form[data-validate]', validateOptions);

      // Detect a successful form validation
      document.addEventListener('bouncerFormValid', function (event) {
          const form = event.target;

          const url = "/ajax/forms.php";
          const formData = new FormData(form);
          const formname = form.getAttribute("name");
          fetch(url, {
            method:'post',
            body: formData,
            headers: new Headers({
              "X-Requested-With": "XMLHttpRequest"
            }),
            credentials: "same-origin"
          }).then(response => {
            return response.json();
          }).then(data => {
            if(data.success) {
              form.closest('.form-response').classList.add('form-response--sended');
              form.reset();
              window.feedback(formname);
            }
          }).catch(error => {

            //TODO REPLACEMENT STUB
            form.reset();
            form.closest('.form-response').classList.add('form-response--sended');
            console.warn(error);
          });
        },
        false);
    }

    const resetBtns = document.querySelectorAll('.form-response__btn');
    [...resetBtns].forEach(btn=>{
      btn.addEventListener('click',function(ev){
        const wrapper = ev.target.closest('.form-response');
        wrapper.classList.remove('form-response--sended');

        let {top} = wrapper.getBoundingClientRect();

        top -= 60

        top += window.scrollY;
        window.scroll({
          top,
          behavior: "smooth"
        });

      })
    })
  }
}
